var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "edit")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center mt-5" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3374301958
              ),
            },
            [
              !_vm.isLoading
                ? _c("div", [
                    _vm.ok
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-card",
                                    { staticClass: "filter" },
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "anno",
                                                label: "Anno",
                                                options: _vm.anni,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input.anno,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "anno",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.anno",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "anno_di_confronto",
                                                label: "Anno Confronto",
                                                options: _vm.anni_di_confronto,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input
                                                    .anno_di_confronto,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "anno_di_confronto",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.anno_di_confronto",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "solo_mese_di",
                                                label: "Solo mese di",
                                                options: _vm.mesi,
                                              },
                                              on: { input: _vm.onInputMeseDi },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input.solo_mese_di,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "solo_mese_di",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.solo_mese_di",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "fino_al_mese",
                                                label: "Fino al mese",
                                                options: _vm.mesi_fino,
                                              },
                                              on: {
                                                input: _vm.onInputMeseFino,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input.fino_al_mese,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "fino_al_mese",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.fino_al_mese",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "formato_rami_statscont",
                                                label: "Tipologie",
                                                options: _vm.tipologie,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input
                                                    .formato_rami_statscont,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "formato_rami_statscont",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.formato_rami_statscont",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "formato_valori_statscont",
                                                label: "Valori",
                                                options: _vm.valori,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input
                                                    .formato_valori_statscont,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "formato_valori_statscont",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.formato_valori_statscont",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "salesmen",
                                                label: "Produttori",
                                                options: _vm.salesmen,
                                                multiple: true,
                                                taggable: true,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input.produttori,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "produttori",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.produttori",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  pressed:
                                                    _vm.myToggleSalesemen,
                                                  variant: "light",
                                                },
                                                on: {
                                                  "update:pressed": function (
                                                    $event
                                                  ) {
                                                    _vm.myToggleSalesemen =
                                                      $event
                                                  },
                                                  click: function ($event) {
                                                    _vm.myToggleSalesemen
                                                      ? _vm.selectAllSalesmen()
                                                      : _vm.deselectAllSalesmen()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.myToggleSalesemen
                                                      ? "Deseleziona tutti"
                                                      : "Seleziona tutti"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "cod_compagnia",
                                                label: "Compagnia",
                                                options: _vm.companies,
                                                multiple: true,
                                                taggable: true,
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input.compagnie,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "compagnie",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.compagnie",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  pressed:
                                                    _vm.myToggleCompanies,
                                                  variant: "light",
                                                },
                                                on: {
                                                  "update:pressed": function (
                                                    $event
                                                  ) {
                                                    _vm.myToggleCompanies =
                                                      $event
                                                  },
                                                  click: function ($event) {
                                                    _vm.myToggleCompanies
                                                      ? _vm.selectAllCompanies()
                                                      : _vm.deselectAllCompanies()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.myToggleCompanies
                                                      ? "Deseleziona tutti"
                                                      : "Seleziona tutti"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "dettagli_per_produttore",
                                                label:
                                                  "Dettagli per Produttore",
                                                options: [
                                                  { value: "Y", text: "Si" },
                                                  { value: "N", text: "No" },
                                                ],
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input
                                                    .dettagli_per_produttore,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "dettagli_per_produttore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.dettagli_per_produttore",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-select", {
                                              attrs: {
                                                name: "solo_riepiloghi",
                                                label: "Solo Riepiloghi",
                                                options: [
                                                  { value: "Y", text: "Si" },
                                                  { value: "N", text: "No" },
                                                ],
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .request_input
                                                    .solo_riepiloghi,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep]
                                                      .request_input,
                                                    "solo_riepiloghi",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].request_input.solo_riepiloghi",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group col-md-6 mt-4 align-self-end",
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-fq",
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                  disabled: _vm.isLoadingPDF,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onReport("pdf")
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-spinner", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.isLoadingPDF,
                                                      expression:
                                                        "isLoadingPDF",
                                                    },
                                                  ],
                                                  attrs: {
                                                    small: "",
                                                    type: "grow",
                                                  },
                                                }),
                                                _vm._v("Genera PDF"),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                  disabled: _vm.isLoadingCSV,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onReport("csv")
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-spinner", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.isLoadingCSV,
                                                      expression:
                                                        "isLoadingCSV",
                                                    },
                                                  ],
                                                  attrs: {
                                                    small: "",
                                                    type: "grow",
                                                  },
                                                }),
                                                _vm._v("Genera CSV"),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-reset",
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClearFilter()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }